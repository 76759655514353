import './App.css';
import './Button.css';
import ParticleBackground from './ParticleBackground';

export default ({toggleHasClicked}: any) => {
  return (
    <div>
      <ParticleBackground backgroundColor="#0000" />
      <div className="button-container">
        <button className="button-63" role="button" onClick={() => toggleHasClicked(true)}>Click here to enter the metaverse</button>
      </div>
    </div>
  );
}
