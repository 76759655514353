import './App.css';
import ParticleBackground from './ParticleBackground';
import Typical from 'react-typical';
import Sound from 'react-sound';

export default () => {
  const alarm = require("./music2.mp3");
  return (
    <div>
      <Sound url={alarm} playStatus={Sound.status.PLAYING} loop/>
      <ParticleBackground/>
      <div className="container">
        <div>Congratulations</div>
        <div className="text">
          <Typical 
            steps={[
              1000,
              "You got fucked in the metaverse.", 500, 
              "Yeah, babyyyyy.", 500, 
              "Was it as good for you as it was for me?", 125,
              "It was probably better for me."
            ]}
          />
        </div>
      <img src="Mark.png"/>
    </div>
    </div>
  );
}
